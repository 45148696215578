import './styles/main.scss';

import OneRouter from 'one-router';

//
//   Global App Variable
//
//////////////////////////////////////////////////////////////////////

window.APP = window.APP || {};
import GlobalRoute from './scripts/routes/global.js';
import StyleGuideRoute from './scripts/routes/styleguide';
import ProductRoute from './scripts/routes/product';
import HomeRoute from './scripts/routes/home';
import BlogRoute from './scripts/routes/blog';
import PartnersRoute from './scripts/routes/partners';
import FindAPartnerRoute from './scripts/routes/findAPartner';
import AboutRoute from './scripts/routes/about';
import InTheFieldRoute from './scripts/routes/inTheField';
import CommerceRoute from './scripts/routes/commerce';
import ContactRoute from './scripts/routes/contact';
import KbArticleRoute from './scripts/routes/kbArticle';
import EnterpriseRoute from './scripts/routes/enterprise';
import SupportServicesRoute from './scripts/routes/supportServices';
import PageContentRoute from './scripts/routes/pageContent';
import DemoRoute from './scripts/routes/demo';
import EventsRoute from './scripts/routes/events';
import PricingRoute from './scripts/routes/pricing';
import CloudLandingRoute from './scripts/routes/cloud-landing';

//
//   Router
//
//////////////////////////////////////////////////////////////////////

var router = new OneRouter({
  '^/styleguide': function() { StyleGuideRoute(); },
  '^/product/?$': function() { ProductRoute(); },
  '^/?$': function() { HomeRoute(); },
  '^/blog': function() { BlogRoute(); },
  '^/partners': function() { PartnersRoute(); },
  '^/partners/find-a-partner': function() { FindAPartnerRoute(); },
  '^/about': function() { AboutRoute(); },
  '^/in-the-field': function() { InTheFieldRoute(); },
  '^/commerce': function() { CommerceRoute(); },
  '^/why-craft-for-developers': function() { CommerceRoute(); },
  '^/contact': function() { ContactRoute(); },
  '^/guides/(.+)$': function() { KbArticleRoute(); },
  '^/enterprise': function() { EnterpriseRoute(); },
  '^/support-services': function() { SupportServicesRoute(); },
  '^/(blog|knowledge-base)': function() { PageContentRoute(); },
  '^/demo': function() { DemoRoute(); },
  '^/events': function() { EventsRoute(); },
  '^/pricing': function() { PricingRoute(); },
  '^/commerce/pricing': function() { PricingRoute(); },
  '^/cloud': function() { PricingRoute(); },
});

//
//   App Initiation
//
//////////////////////////////////////////////////////////////////////
APP.mediaQueries = {
  small: 'screen and (min-width: 500px)',
  medium: 'screen and (min-width: 768px)',
  large: 'screen and (min-width: 1024px)',
  xlarge: 'screen and (min-width: 1368px)',
  xxlarge: 'screen and (min-width: 1596px)',
  hamburger: 'screen and (max-width: 768px)'
};

APP.init = function() {
  GlobalRoute();
};

//
//   Kickoff
//
//////////////////////////////////////////////////////////////////////

APP.init();
